function renderTime(): void {
  const now = new Date()
  const hours = now.getHours()
  const minutes = now.getMinutes()
  const timeString = `${hours}時${minutes}分`

  // .timeDisplayクラスを持つすべての要素を取得
  const timeDisplays = document.querySelectorAll('.timeDisplay')

  // NodeListをループして、各要素に時間を設定
  timeDisplays.forEach((display) => {
    display.textContent = timeString
  })
}

// .timeDisplayクラスを持つ要素が存在するか確認
if (document.querySelector('.timeDisplay')) {
  setInterval(renderTime, 30000) // 30秒ごとに更新
  renderTime() // 初回の描画
}

document.addEventListener('DOMContentLoaded', () => {
  // SPのみでこの機能を有効にするためのメディアクエリ
  const mediaQuery = window.matchMedia('(max-width: 768px)')

  const fixedBtn = document.getElementById('fixedBtn')
  const btnShow = document.getElementById('btnShow')

  if (!fixedBtn || !btnShow) {
    return
  }

  const toggleButtonVisibility = () => {
    // `#btnShow`のページ上の位置（トップ）を取得
    const btnShowTopPosition = btnShow.getBoundingClientRect().top + window.pageYOffset
    // ビューポートの高さを取得
    const viewportHeight = window.innerHeight
    // 現在のスクロール位置 + ビューポートの高さが`#btnShow`のトップ位置を超えたかどうかをチェック
    if (window.pageYOffset + viewportHeight > btnShowTopPosition) {
      fixedBtn.style.display = 'block' // 表示
    } else {
      fixedBtn.style.display = 'none' // 非表示
    }
  }

  if (mediaQuery.matches) { // メディアクエリがtrueの場合のみ以下を実行
    // スクロール時に表示を切り替えるイベントリスナーを追加
    window.addEventListener('scroll', toggleButtonVisibility)

    // ページ読み込み時にも一度チェックを行う
    toggleButtonVisibility()
  }
})