const nameInput = document.getElementById('nameInput') as HTMLInputElement,
    addressInput = document.getElementById('addressInput') as HTMLInputElement,
    emailInput = document.getElementById('emailInput') as HTMLInputElement,
    telInput = document.getElementById('telInput') as HTMLInputElement,
    consentCheckbox = document.getElementById('consentCheckbox') as HTMLInputElement,

    nameValidMessage = document.getElementById('nameValidMessage') as HTMLInputElement,
    addressValidMessage = document.getElementById('addressValidMessage') as HTMLInputElement,
    emailValidMessage = document.getElementById('emailValidMessage') as HTMLInputElement,
    telValidMessage = document.getElementById('telValidMessage') as HTMLInputElement,
    consentValidMessage = document.getElementById('consentValidMessage') as HTMLInputElement,

    contactFirstStepSubmitBtn = document.getElementById('contactFirstStepSubmitBtn') as HTMLButtonElement


if(contactFirstStepSubmitBtn) {

    contactFirstStepSubmitBtn.addEventListener("click", (event) => {
        let errorFlag:boolean = false

        // 名前
        if(nameInput.value === '') {
            nameValidMessage.classList.add('active')
            errorFlag = true
        } else {
            nameValidMessage.classList.remove('active')
        }

        // お住まいの地域
        if(addressInput.value === '') {
            addressValidMessage.classList.add('active')
            errorFlag = true
        } else {
            addressValidMessage.classList.remove('active')
        }

        // メールアドレス
        if(emailInput.value === '' || !isValidEmail(emailInput.value)) {
            emailValidMessage.classList.add('active')
            errorFlag = true
        } else {
            emailValidMessage.classList.remove('active')
        }

        // 電話番号
        if(telInput.value === '') {
            telValidMessage.classList.add('active')
            errorFlag = true
        } else {
            telValidMessage.classList.remove('active')
        }
        // プライバシーポリシー同意
        if(!consentCheckbox.checked) {
            consentValidMessage.classList.add('active')
            errorFlag = true
        } else {
            consentValidMessage.classList.remove('active')
        }

        if(errorFlag) {
            event.preventDefault()

             // ページ内リンクを使用して #contactSection にスクロール
            const contactSection = document.getElementById("contactSection")
            if (contactSection) {
                contactSection.scrollIntoView({ behavior: "smooth" })
            }
        }
    })
}

function isValidEmail (email: string): boolean {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
}